import xs from "xstream";
import firebase from "./firebase";
import { makeState, withErrors, hasErrors } from "./form-control";

const handleErrors = {
  "auth/wrong-password": () =>
    "The password you entered didn't match the password for this account.",
  "auth/invalid-email": () => "The email you entered is invalid.",
  "auth/user-not-found": () =>
    "We couldn't find a user with the email you entered.",
  unknown: () =>
    "Hmm... something went wrong but we're not quite sure what's going on. Please try again, if the issue persists, you can send us a message at admin@gethallo.com."
};

export default ({ strict, $, scope }) => {
  const navigation$ = scope.props.map(props => props.navigation);
  const errorsProxy$ = xs.create();
  const errorsProxyVal$ = xs.create().startWith(false);

  const email = withErrors(errorsProxyVal$, makeState("", strict), email =>
    email != null && email.length > 0 ? [] : ["Please enter your email."]
  );

  const password = withErrors(
    errorsProxyVal$,
    makeState("", strict),
    password =>
      password != null && password.length > 0
        ? []
        : ["Please enter your password."]
  );

  const formHasErrors = hasErrors([email, password]);
  const showErrors$ = $.tryLogin.compose(scripts.sample(formHasErrors));
  errorsProxy$.imitate(showErrors$);

  const submission$ = $.tryLogin
    .compose(scripts.sample(formHasErrors))
    .filter(hasErrors => !hasErrors)
    .compose(
      scripts.sample(
        scripts.combineObject({ email: email.value, password: password.value })
      )
    );

  const getLoggedIn = () =>
    submission$
      .map(({ email, password }) => {
        return xs.fromPromise(
          firebase.auth().signInWithEmailAndPassword(email, password)
        );
      })
      .flatten()
      .mapTo({ type: "success" })
      .replaceError(error => {
        if (error && handleErrors.hasOwnProperty(error.code)) {
          return getLoggedIn().startWith({
            type: "error",
            message: handleErrors[error.code](error)
          });
        } else {
          console.error(error);
          return {
            type: "error",
            message: handleErrors.unknown(error)
          };
        }
      });

  const loggedIn$ = getLoggedIn();

  const loading$ = xs
    .merge(submission$.mapTo(true), loggedIn$.mapTo(false))
    .startWith(false);

  strict(
    loggedIn$
      .filter(({ type }) => type === "success")
      .compose(
        scripts.sample(navigation$, navigation => navigation.navigate("app"))
      )
  );

  return {
    email,
    password,
    loading: loading$,
    loginResponse: loggedIn$.startWith({ type: "ok" })
  };
};
