import { Platform, View } from "react-native";
import makeComponent from "./makeComponent";
import * as React from "react";

export default makeComponent(
  ({ onPress, children, ...props }) => {
    return (
      <View
        {...props}
        {...(Platform.OS === "web" ? { onClick: onPress } : { onPress })}
      >
        {children}
      </View>
    );
  },
  { acceptStyles: true }
);
