import xs from "xstream";
import { useMemo, useEffect } from "react";

export const mkMap = ({ scope, children, key, prop }) => {
  return props => {
    const $props = useMemo(() => {
      return xs.create();
    }, []);

    useEffect(() => {
      $props.shamefullySendNext(props[prop]);
    }, [props]);

    useEffect(() => {
      return () => {
        $props.shamefullySendComplete();
      };
    }, []);

    return useMemo(() => {
      return scope
        .branch(new Map([[key, $props.remember()]]))
        .evalChildren(children);
    }, []);
  };
};

export default (zenComponent, ...props) => {
  const componentScope = zenComponent.scope;
  const assign = zenComponent.assign;
  const addProps = Object.fromEntries(scripts.parseProps(props));

  const Component = props => {
    const $props = useMemo(() => {
      return xs.create().remember();
    }, []);

    useEffect(() => {
      $props.shamefullySendNext({ ...addProps, ...props });
    }, [props]);

    useEffect(() => {
      return () => {
        $props.shamefullySendComplete();
      };
    }, []);

    return useMemo(() => {
      return componentScope
        .branch(new Map([["props", $props.remember()]]))
        .eval(assign.value);
    }, []);
  };

  Component.isScreen = true;

  return Component;
};
