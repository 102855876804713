import xs from "xstream";
import cachedRequests from "../.cached-assets/cached-requests.json";
import defer from "./defer";
import strapi from "./strapi";

const key = "featureImages";

const getFromCache = id => {
  return xs.of(cachedRequests[key]).remember();
};

export default defer(() =>
  getFromCache(() =>
    xs.fromPromise(strapi.getEntries("featureimages", { public_eq: true }))
  )
).remember();
