import {
  format,
  differenceInCalendarYears,
  differenceInCalendarDays
} from "date-fns";

export default ({ type, scheduledTime }) => {
  if (type === "asap") {
    return "ASAP";
  }

  const date = new Date(scheduledTime);
  const now = Date.now();

  if (differenceInCalendarDays(now, date) === 0) {
    return format(date, "h:mm a");
  }

  if (differenceInCalendarYears(now, date) === 0) {
    return format(date, "MMM d, h:mm a");
  }

  return format(date, "MMM d 'yy, h:mm a");
};
