export default ({ $, scope, strict }) => {
  strict(
    $.continue.compose(
      scripts.sample(scope.props, props => {
        const params = props.navigation.state.params;

        if (params && params.continue) {
          props.navigation.pop();
          props.navigation.push(params.continue.route, params.continue.params);
        } else {
          props.navigation.pop();
        }
      })
    )
  );

  return {
    visible: $.dismiss.mapTo(false).startWith(true),
    done: $.done,
    dismiss: $.dismiss,
    savedAddressControl: {
      submit: { event: $.done },
      done: scripts.cb($.dismiss)
    }
  };
};
