export default ({ $, scope }) => {
  const navigation = scope.props.map(props => props.navigation).remember();

  return {
    showSkills: category => {
      return () => {
        scripts.consume(
          navigation.map(navigation => {
            navigation.navigate("skills", { category });
          })
        );
      };
    }
  };
};
