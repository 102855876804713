import invariant from "invariant";
import { createBottomTabNavigator } from "react-navigation-tabs";
import * as React from "react";
import withLoadingFallback from "./withLoadingFallback";

export default (scope, element) => {
  invariant(
    element.children.every(child => child.type === "Assign"),
    "Expected children of bottom navigation to be page assignments"
  );

  const icons = new Map();

  return createBottomTabNavigator(
    Object.fromEntries(
      element.children.map(child => {
        const evaled = scope.eval(child.value);

        invariant(
          evaled.icon != null && evaled.view != null,
          "Expected children of bottom navigation to have icon and view"
        );

        icons.set(child.name, evaled);

        return [
          child.name,
          evaled.view.isScreen ? withLoadingFallback(evaled.view) : evaled.view
        ];
      })
    ),

    {
      tabBarOptions: {
        style: scripts.parseKeywordStyles(element.props)
      },
      defaultNavigationOptions: ({ navigation }) => {
        return {
          tabBarIcon({ focused }) {
            const { routeName } = navigation.state;
            const Icon = icons.get(routeName).icon;
            return <Icon focused={focused} routeName={routeName} />;
          },

          tabBarLabel({ focused }) {
            const { routeName } = navigation.state;
            const Label = icons.get(routeName).label;
            return (
              <Label key={routeName} focused={focused} routeName={routeName} />
            );
          }
        };
      }
    }
  );
};
