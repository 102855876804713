import { Stream } from "xstream";

export const getKeywordStyles = element => {
  return scripts.parseKeywordStyles(
    element.props.filter(props => props.type === "Identifier")
  );
};

export const combineStyles = (style, keywordStyles) => {
  return [keywordStyles, style].flat();
};

export const combinePropsStyles = (props, element) => {
  const keywordStyles = getKeywordStyles(element);

  if (props.style == null) {
    return { ...props, style: keywordStyles };
  }

  if (props.style instanceof Stream) {
    return {
      ...props,
      style: props.style.map(style => {
        if (style == null) {
          return keywordStyles;
        }

        return combineStyles(style, keywordStyles);
      })
    };
  }

  return { ...props, style: combineStyles(props.style, keywordStyles) };
};

export const letPropsAndChildren = (
  acceptStyles,
  passProps,
  element,
  scope
) => {
  let props = Object.fromEntries(
    scripts
      .parseProps(element.props)
      .map(([name, value]) => [name, scope.eval(value)])
  );

  props = acceptStyles ? combinePropsStyles(props, element) : props;

  const childScope = scope;

  const children = childScope.letChildren(element.children);

  return { props, children, scope: childScope };
};

export default (acceptStyles, passProps, element, scope) => {
  let props = Object.fromEntries(
    scripts
      .parseProps(element.props)
      .map(([name, value]) => [name, scope.eval(value)])
  );

  props = acceptStyles ? combinePropsStyles(props, element) : props;

  const childScope = scope;

  const children = childScope.evalChildren(element.children);

  return { props, children, scope: childScope };
};
