import xs from "xstream";
import firebase from "./firebase";
import { makeState, makeForm, withErrors, hasErrors } from "./form-control";

const handleErrors = {
  "auth/email-already-in-use": () =>
    "An email for this account already exists.",
  "auth/wrong-password": () =>
    "The password you entered didn't match the password for this account.",
  "auth/invalid-email": () => "The email you entered is invalid.",
  "auth/user-not-found": () =>
    "We couldn't find a user with the email you entered.",
  unknown: () =>
    "Hmm... something went wrong but we're not quite sure what's going on. Please try again, if the issue persists, you can send us a message at admin@gethallo.com."
};

export default ({ strict, $, scope }) => {
  const navigation$ = scope.props.map(props => props.navigation);
  const errorsProxy$ = xs.create();
  const errorsProxyVal$ = xs.create().startWith(false);

  const form = makeForm($.trySignup, {
    inviteCode: {
      state: makeState("", strict),
      getErrors: inviteCode =>
        inviteCode != null && inviteCode.length > 0
          ? []
          : ["You'll need an invite code to join Hallo."]
    },

    email: {
      state: makeState("", strict),
      getErrors: email =>
        email != null && email.length > 0 ? [] : ["Please enter your email."]
    },

    password: {
      state: makeState("", strict),
      getErrors: password =>
        password != null && password.length > 0
          ? []
          : ["Please enter your password."]
    }
  });

  const submission$ = form.submit.compose(scripts.sample(form.state));

  const getSignup = () =>
    submission$
      .map(({ inviteCode, email, password }) => {
        return xs.fromPromise(
          firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(user => {
              return firebaseApp
                .firestore()
                .collection("users")
                .doc(user.user.uid)
                .set({
                  roles: [],
                  serviceProvider: null,
                  email: email,
                  firstName: "",
                  lastName: ""
                })
                .then(() => user);
            })
        );
      })
      .flatten()
      .mapTo({ type: "success" })
      .replaceError(error => {
        if (error && handleErrors.hasOwnProperty(error.code)) {
          const errorMessage = handleErrors[error.code];

          return getSignup().startWith({
            type: "error",
            message: !handleErrors.hasOwnProperty(error.code)
              ? handleErrors.unknown()
              : errorMessage()
          });
        } else {
          return xs.of({
            type: "error",
            message: handleErrors.unknown(error)
          });
        }
      })
      .debug("signup");

  const signedUp = getSignup();

  const loading$ = xs
    .merge(submission$.mapTo(true), signedUp.mapTo(false))
    .startWith(false);

  strict(
    signedUp
      .filter(({ type }) => type === "success")
      .compose(
        scripts.sample(navigation$, navigation => navigation.navigate("app"))
      )
  );

  return {
    ...form.fields,
    loading: loading$,
    response: signedUp.startWith({ type: "ok" })
  };
};
