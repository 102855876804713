import withLoadingFallback from "./withLoadingFallback";

export default (scope, element) =>
  Object.fromEntries(
    element.children.map(child => {
      const evaled = scope.eval(child.value);

      return [
        child.name,
        evaled.isScreen
          ? Object.assign(withLoadingFallback(evaled), evaled)
          : evaled
        //evaled
      ];
    })
  );
