import RedBox from "redbox-react";
import * as React from "react";

export class RedboxBoundary extends React.Component {
  state = {};

  static getDerivedStateFromError(e) {
    return { e: e || true };
  }

  render() {
    if (this.state.e) {
      return null;
    }

    return this.props.children;
  }
}
