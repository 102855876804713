import getSkillsByIds from "./getSkillsByIds";

export default ({ scope }) => {
  const booking$ = scope["booking-request"];

  return {
    skills: booking$
      .map(booking => {
        return getSkillsByIds(booking.categoryId, booking.skills);
      })
      .flatten()
      .remember()
  };
};
