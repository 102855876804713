import * as React from "react";
import Suspense from "./Suspend";
import { View } from "react-native";

export default Component => props => {
  return (
    <Suspense style={{ alignSelf: "stretch", flex: 1 }} fallback={<View />}>
      <Component {...props} />
    </Suspense>
  );
};
