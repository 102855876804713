import xs from "xstream";
import { AsyncStorage } from "react-native";

const collectionData = {};

export const collection = name => {
  return {
    query() {
      if (collectionData.hasOwnProperty(name)) {
        return collectionData[name];
      } else {
        const stored = xs
          .fromPromise(AsyncStorage.getItem(`collection/${name}`))
          .map(value => {});
      }
    }
  };
};
