import { getSnapshot } from "./user-profile";

const getDocument = documentRef => {
  const snapshot$ = getSnapshot(documentRef);

  return snapshot$.map(snapshot => {
    const data = snapshot.data();

    if (data != null) {
      return { ...snapshot.data(), key: snapshot.id };
    }

    return null;
  });
};

const getBookingRequestById = id => {
  return getDocument(`bookings/${id}`);
};

export default getBookingRequestById;
