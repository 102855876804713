import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle as fasCheckCircle,
  faAngleRight,
  faStar,
  faPlusSquare,
  faPaperPlane,
  faMinusSquare
} from "@fortawesome/pro-solid-svg-icons";
import {
  faLock,
  faLocation,
  faEnvelope,
  faMapMarkerAlt,
  faSearch,
  faAngleLeft
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { createElement as h } from "react";
import makeComponent from "./makeComponent";

library.add(
  faLock,
  faPaperPlane,
  faEnvelope,
  faLocation,
  faMinusSquare,
  faMapMarkerAlt,
  faSearch,
  faAngleLeft,
  faPlusSquare,
  faStar,
  fasCheckCircle,
  faAngleRight
);

const Icon = FontAwesomeIcon;

export default makeComponent(
  ({ __props, type: iconStyle, name, style, ...props }) => {
    return h(Icon, {
      icon: [iconStyle, name],
      style:
        style && Array.isArray(style) ? Object.assign({}, ...style) : style,
      ...props
    });
  },
  { acceptStyles: true }
);
