import { View } from "react-native";
import * as React from "react";
import makeComponent from "./makeComponent";

View.propTypes = null;

export default makeComponent(
  ({ source, children, style, resizeMode, ...props }) => {
    console.log(source.uri);
    const imageStyle = {
      backgroundSize: resizeMode || "cover",
      backgroundPosition: "center",
      backgroundImage: `url(${
        typeof source === "string" ? source : source.uri
      })`
    };

    return <View source={source} style={[imageStyle, style]} {...props} />;
  },
  { acceptStyles: true }
);
