import * as React from "react";
import { ScrollView } from "react-native";
import makeComponent from "./makeComponent";

export default makeComponent(
  ({ content, ...props }) => {
    return <ScrollView {...props} contentContainerStyle={content} />;
  },
  { acceptStyles: true }
);
