import userProfile from "./user-profile";
import firebase from "./firebase";
import xs from "xstream";

export default userProfile
  .map(profile => {
    return xs.fromPromise(
      firebase
        .storage()
        .ref(`profiles/${profile.key}.jpg`)
        .getDownloadURL()
    );
  })
  .flatten()
  .map(value => ({ uri: value }))
  .replaceError(e => {
    if (e.code === 404) {
      return of(null);
    }

    return xs.throw(e);
  })
  .remember();
