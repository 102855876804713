import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import { Platform } from "react-native";
import getEnv from "../environment";

const { functionsURL, firebaseConfig } = getEnv();

let oldWindow;
if (Platform.OS !== "web") {
  oldWindow = window;
  window = undefined;
}

const makeApp = () => {
  const app = firebase.initializeApp(firebaseConfig);
  firebase.firestore().enablePersistence();
  return app;
};

const app = firebase.apps[0] || makeApp();

export default app;

if (functionsURL) {
  app.functions().useFunctionsEmulator(functionsURL);
}

if (Platform.OS !== "web") {
  window = oldWindow;
}
