import firebase from "./firebase";

export default ({ $, strict, scope }) => {
  const navigation$ = scope.props.map(({ navigation }) => navigation);

  strict(
    $.navigate.compose(
      scripts.sample(navigation$, (navigation, to) => navigation.push(to))
    )
  );

  strict(
    $.logout.map(() => {
      firebase.auth().signOut();
      window.location.href = "/";
    })
  );

  return {};
};
