import * as React from "react";
import scripts from "./scripts";
import { YellowBox } from "react-native";
import { RedboxBoundary } from "./scripts/handle-errors";

(global as any).scripts = scripts;
YellowBox.ignoreWarnings(["Accessing view manager"]);

import app from "./zen-app.json";
import { render, Scope } from "./render";

const Child = React.memo(({ scripts, app }: any) => {
  return render(new Scope(scripts), app);
});

let instance;

export default class App extends React.Component {
  state = {
    scripts,
    app
  };

  componentWillMount() {
    instance = this;
  }

  componentWillUnmount() {
    instance = null;
  }

  render() {
    const { scripts, app } = this.state;

    const scriptsLocals = new Map(Object.entries(scripts));

    if (RedboxBoundary) {
      return (
        <RedboxBoundary>
          <Child scripts={scriptsLocals} app={app[0]} />
        </RedboxBoundary>
      );
    }

    return <Child scripts={scriptsLocals} app={app[0]} />;
  }
}

if ((module as any).hot) {
  (module as any).hot.accept(["./scripts", "./zen-app.json"], () => {
    if (instance) {
      instance.setState({
        scripts: require("./scripts").default,
        app: require("./zen-app.json")
      });
    }
  });
}
