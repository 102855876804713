import { View } from "react-native";
import makeComponent from "./makeComponent";
import * as React from "react";

export default makeComponent(
  ({ style, ...props }) => {
    return (
      <View
        style={[{ backgroundColor: "rgba(0, 0, 0, 0.05)" }, style]}
        {...props}
      />
    );
  },
  { acceptStyles: true }
);
