import AutosizeTextArea from "react-textarea-autosize";
import makeComponent from "./makeComponent";
import * as React from "react";
import createControlled from "./createControlled";

const Controlled = createControlled(
  AutosizeTextArea,
  ["value", "onChange"],
  v => v,
  e => e.target.value
);

export default makeComponent(
  props => {
    return <Controlled className="reset-auto-textarea" {...props} />;
  },
  { acceptStyles: true }
);
