const phonePattern = /^([0-9])?([0-9]{3})([0-9]{3})([0-9]{4})$/;

const formatCountry = country => {
  return `+${country} `;
};

export default phone => {
  const match = phone.match(phonePattern);

  if (match == null) {
    return "";
  }

  const [, countryCode = "", area, local1, local2] = match;

  return `${countryCode &&
    formatCountry(countryCode)}(${area}) ${local1}-${local2}`;
};
