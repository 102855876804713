import makeComponent from "./makeComponent";
import { TextInput } from "react-native";
import createControlled from "./createControlled";
import React from "react";
import { Platform } from "react-native";

if (Platform.OS === "web") {
  var style = document.createElement("style");
  style.innerHTML = `
    textarea:focus {
        outline: none!important;
    }

    input:focus {
        outline: none!important;
    }
    `;
  document.head.appendChild(style);
}

const MyTextField = ({ children, ...props }) => {
  return <TextInput {...props} style={props.style} />;
};

const Controlled = createControlled(MyTextField, ["value", "onChangeText"]);
const TextField = makeComponent(Controlled, { acceptStyles: true });

TextField.displayName = "ZenTextField";

export default TextField;
