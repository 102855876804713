import xs from "xstream";

export default getStream => {
  return xs.create({
    start(sink) {
      this.sub = getStream().subscribe(sink);
    },

    stop() {
      if (this.sub) {
        this.sub.unsubscribe();
        this.sub = null;
      }
    }
  });
};
