const colors = {
  primary: "#00B162",
  "default-text": "#393939",
  "label-text": "#7B869B",
  "active-light": "#FF9266",
  "active-lighter": "#FE9870",
  "button-primary": "#36CC00",
  "gray-transparent": "rgba(0, 0, 0, 0.2)",
  "primary-light": "#ABFF8D",
  "primary-transparent": "rgba(0, 177, 98, 0.7)",
  white: "#ffffff",
  light: "#F7F7FB",
  "light-transparent": "rgba(255, 255, 255, 0.5)",
  "light-gray-transparent": "rgba(0, 0, 0, 0.05)",
  "icon-gray": "#7E8699",
  transparent: "transparent",
  error: "#BC3129"
};

export default colors;
