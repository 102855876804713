export default {
  order_history: require("../assets/xd/ic_order_history.png"),
  profile: require("../assets/xd/ic_profile.png"),
  invite_friends: require("../assets/xd/ic_invite_friend.png"),
  saved_address: require("../assets/xd/ic_delivery_address.png"),
  settings: require("../assets/xd/ic_setting.png"),
  camera: require("../assets/xd/ic_camera.png"),
  secure: require("../assets/xd/ic-reset-success.png"),
  exit: require("../assets/xd/exit.png")
};
