import invariant from "invariant";
import * as React from "react";
import xs from "xstream";

const control = (scope, element) => {
  invariant(
    element.props[0] != null && element.props[0].type === "Identifier",
    `expected control to declare script as first identifier`
  );
  const name = element.props[0].name;
  const controlScript = scope.get(name);

  const selectors = new Map();
  const selectorProxy = new Proxy(
    {},
    {
      get(target, name) {
        if (selectors.has(name)) {
          return selectors.get(name);
        }

        const subject = xs.create();
        const stream = subject.endWhen(lifecycle);

        stream.cb = value => {
          return stream.shamefullySendNext(value);
        };

        stream.with = value => {
          return () => stream.shamefullySendNext(value);
        };

        selectors.set(name, stream);

        return stream;
      }
    }
  );

  const scopeProxy = new Proxy(
    {},
    {
      get(target, name) {
        return scope.get(name);
      }
    }
  );

  const lifecycle = xs.create();

  const strict = stream => {
    const childStream = stream.endWhen(lifecycle);
    scripts.consume(childStream);
    return childStream;
  };

  const sinks = controlScript({
    $: selectorProxy,
    lifecycle,
    strict,
    scope: scopeProxy
  });
  const childScope = scope.branch(
    new Map([[name, { ...sinks, $: selectorProxy }], ["$", selectorProxy]])
  );
  const children = childScope.evalChildren(element.children);

  const Component = () => {
    React.useEffect(() => {
      return () => {
        lifecycle.shamefullySendComplete();
      };
    });

    return children;
  };

  return <Component />;
};

export default control;
