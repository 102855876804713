import { makeState } from "./form-control";
import { AsyncStorage } from "react-native";
import consume from "./consume";
import xs from "xstream";

const key = "hallo:appointmentDate";

const loadDate$ = xs
  .from(AsyncStorage.getItem(key))
  .map(value => (value == null ? { type: "asap" } : JSON.parse(value)))
  .map(value =>
    value.type === "scheduled" && Date.now() > value.scheduledTime
      ? { type: "asap" }
      : value
  );

const state = makeState(loadDate$, consume);

state.value.subscribe({
  next(value) {
    AsyncStorage.setItem(key, JSON.stringify(value));
  },

  error(e) {
    console.error(e);
  }
});

export default state;
