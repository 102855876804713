import * as React from "react";
import { Text } from "react-native";
import makeComponent from "./makeComponent";
import colors from "./colors";

const component = makeComponent(
  ({ text, font, ...props }) => {
    return (
      <Text
        {...props}
        style={[
          {
            fontFamily: font || "Montserrat",
            color: colors["default-text"]
          },
          props.style
        ]}
      >
        {text}
      </Text>
    );
  },
  { acceptStyles: true }
);

export default (scope, element) => {
  const props = [...element.props];
  const text = props.pop();
  props.push({ type: "Keyword", id: { type: "Identifier", name: "text" } });
  props.push(text);

  return component(scope, {
    ...element,
    props
  });
};
