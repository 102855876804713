import xs from "xstream";
import * as React from "react";
import dropRepeats from "xstream/extra/dropRepeats";
import StreamProps from "./ObservableProps";

const RenderChild = ({ child }) => child;

export default (scope, element) => {
  const condition = scope.eval(element.props[0]);

  const el$ = (!scripts.isStream(condition) ? xs.of(condition) : condition)
    .map(condition => (condition ? element.children : null))
    .compose(dropRepeats())
    .map(value => value && scope.evalChildren(value));

  return (
    <StreamProps
      value={scripts.combineObject({ child: el$ })}
      children={[]}
      Component={RenderChild}
    />
  );
};
