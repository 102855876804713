import {
  format,
  differenceInCalendarYears,
  differenceInCalendarDays
} from "date-fns";

export default time => {
  if (time === "ASAP") {
    return "ASAP";
  }

  const date = new Date(time.seconds * 1000);
  const now = Date.now();

  if (differenceInCalendarDays(now, date) === 0) {
    return format(date, "'on' h:mm a");
  }

  if (differenceInCalendarYears(now, date) === 0) {
    return format(date, "'on' MMM d 'at' h:mm a");
  }

  return format(date, "'on' MMM d '_'yy 'at' h:mm a").replace("_", "'");
};
