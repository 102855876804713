import getBookings from "./getBookings";
import $userProfile from "./user-profile";

export default ({ strict, scope, $ }) => {
  const navigation$ = scope.props.map(({ navigation }) => navigation);
  const bookings = strict(
    $userProfile
      .map(profile => getBookings(profile.key))
      .flatten()
      .map(bookings => bookings.filter(v => v.createDate != null))
  );

  strict(
    $.navigateBooking.compose(
      scripts.sample(navigation$, (navigation, id) => {
        navigation.navigate("appointment-detail", { bookingId: id });
      })
    )
  );

  return {
    bookings
  };
};
