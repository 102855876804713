import Constants from "expo-constants";
import { Platform } from "react-native";

const stripePK = process.env.STRIPE_PK;
const firebaseConfig = JSON.parse(process.env.FIREBASE_CONFIG);

const ENV = {
  dev: {
    functionsURL:
      Platform.OS !== "android"
        ? "http://localhost:5000"
        : "http://10.0.2.2:5000",
    stripePK: stripePK,
    firebaseConfig: firebaseConfig
  },

  prod: {
    functionsURL: null,
    stripePK: stripePK,
    firebaseConfig: firebaseConfig
  }
};

const getEnvVars = (env = Constants.manifest.releaseChannel) => {
  if (__DEV__) {
    return ENV.dev;
  } else {
    return ENV.prod;
  }
};

export default getEnvVars;
