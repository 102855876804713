import sampleCombine from "xstream/extra/sampleCombine";

const identity = v => v;

const sample = (value, map = identity) => stream => {
  return stream.compose(sampleCombine(value)).map(args => {
    return map(...args.reverse());
  });
};

export default sample;
