import xs, { Stream } from "xstream";

const combineObject = value => {
  const results = Object.entries(value).map(([key, value$]) =>
    value$ instanceof Stream
      ? value$.map(value => [key, value])
      : xs.of([key, value$]).remember()
  );

  return xs
    .combine(...results)
    .map(results => Object.fromEntries(results))
    .remember();
};

export default combineObject;
