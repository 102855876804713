import { makeForm, makeState } from "./form-control";
import $userProfile from "./user-profile";
import xs from "xstream";
import firebase from "./firebase";

const rule = (validate, message) => value => (validate(value) ? [] : [message]);

const nonPhoneChars = /[^0-9]/g;

export default ({ scope, strict, $ }) => {
  const navigation$ = scope.props.map(prop => prop.navigation);
  const saveClicked$ = scope["save-button-clicked"].event;

  const { fields, state, submit } = makeForm(saveClicked$, {
    firstName: {
      state: makeState(
        $userProfile.map(profile => profile.firstName || ""),
        strict
      ),
      getErrors: () => []
    },

    lastName: {
      state: makeState(
        $userProfile.map(profile => profile.lastName || ""),
        strict
      ),
      getErrors: () => []
    },

    phoneNumber: {
      state: makeState(
        $userProfile.map(profile =>
          profile.phoneNumber ? scripts.formatPhone(profile.phoneNumber) : ""
        ),
        strict
      ),
      getErrors: rule(phone => {
        if (phone == null || phone.trim() === "") {
          return true;
        }

        const cleaned = String(phone).replace(nonPhoneChars, "");

        return cleaned.length === 10 || cleaned.length === 11;
      }, "Please enter a valid 10 or 11 digit phone number")
    }
  });

  const submitted$ = strict(
    submit
      .compose(
        scripts.sample(
          xs.combine(state, $userProfile),
          ([{ phoneNumber, ...rest }, { key, ...v }]) => {
            console.log(v);
            return xs.fromPromise(
              firebase
                .firestore()
                .doc(`users/${key}`)
                .update({
                  phoneNumber: phoneNumber.replace(nonPhoneChars, ""),
                  ...rest
                })
            );
          }
        )
      )
      .map(() => ({ type: "success" }))
      .replaceError(e => {
        console.error(e);
        return of({
          type: "error",
          message:
            "An unknown issue occured. Please try again later, or you can contact us at admin@gethallo.com"
        });
      })
  );

  const loading$ = xs
    .merge(submit.mapTo(true), submitted$.mapTo(false))
    .startWith(false);

  strict(
    submitted$
      .filter(({ type }) => type === "success")
      .compose(scripts.sample(navigation$, nav => nav.pop()))
  );

  return {
    ...fields,
    loading: loading$
  };
};
