import { addDays, format, parse } from "date-fns";
import React, { Component } from "react";
import Picker from "react-mobile-picker";
import { View } from "react-native";
import styled from "styled-components";
import makeComponent from "./makeComponent";

const makeOptions = () => {
  const today = new Date();

  const dates = Array.from(new Array(52)).map((value, index) => {
    return format(addDays(today, index), "PP");
  });

  const hours = Array.from(new Array(12)).map((value, index) => {
    return String(index + 1);
  });

  const minutes = Array.from(new Array(6)).map((value, index) => {
    return String(index * 10).padStart(2, "0");
  });

  return {
    dates,
    hours,
    minutes,
    ampm: ["AM", "PM"]
  };
};

const Container = styled.div`
  font-family: "Montserrat";

  .picker-column:nth-of-type(1) {
    flex: auto;
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

const tenMinutes = 10 * 60 * 1000;

const dateFromValues = ({ dates, minutes, hours, ampm }) => {
  const format = `PP h:mm aa`;

  return parse(`${dates} ${hours}:${minutes} ${ampm}`, format, new Date());
};

const getValues = date => {
  return {
    dates: format(date, "PP"),
    minutes: format(date, "mm"),
    hours: format(date, "h"),
    ampm: format(date, "aa")
  };
};

export default makeComponent(
  class App extends Component {
    options = makeOptions();

    handlePickerChange = (name, value) => {
      const values = getValues(this.props.date);
      const date = dateFromValues({
        ...values,
        [name]: value
      });

      this.props.onChange(date);
    };

    Render = () => {
      const { date } = this.props;
      const values = getValues(date);

      return (
        <Container style={{ fontFamily: "Montserrat" }}>
          <Picker
            onChange={this.handlePickerChange}
            optionGroups={this.options}
            valueGroups={values}
          />
        </Container>
      );
    };

    render = () => {
      const { Render } = this;
      return (
        <div style={{ touchAction: "none" }}>
          <Render />
        </div>
      );
    };
  },
  { acceptStyles: true }
);
