import firebase from "./firebase";
import xs from "xstream";

export default userId => {
  return xs
    .create({
      start(sink) {
        this.sub = firebase
          .firestore()
          .collection("bookings")
          .where("client", "==", firebase.firestore().doc(`users/${userId}`))
          .onSnapshot(sink);
      },

      stop() {
        if (this.sub) {
          this.sub();
        }
      }
    })
    .map(data => {
      return data.docs.map(doc => ({ ...doc.data(), key: doc.id }));
    })
    .remember();
};
