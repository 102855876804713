import * as React from "react";
import combineObject from "./combineObject";
import { combinePropsStyles } from "./evalPropsAndChildren";
import ObserveProps from "./ObservableProps";
import Swiper from "react-native-web-swiper";
import { View } from "react-native";

const makeComponent = Component => {
  const value = (scope, element) => {
    let props = scripts.evalProps(scope, element);

    props = combinePropsStyles(props, element);
    const children = element.children.map(child => scope.eval(child));

    if (scripts.objectHasStream(props)) {
      return (
        <ObserveProps
          value={combineObject(props).remember()}
          Component={Component}
          children={children}
        />
      );
    }

    return React.createElement(Component, props, ...children);
  };

  value.acceptStyles = true;

  return value;
};

const MySwiper = ({ children, ...props }) => {
  return (
    <View style={props.style}>
      <Swiper controlsEnabled={false} maxDistanceForAction={0.05} {...props}>
        {children}
      </Swiper>
    </View>
  );
};

export default makeComponent(MySwiper);
