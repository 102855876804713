import * as React from "react";
import { View } from "react-native";
import Swiper from "react-native-web-swiper";
import makeComponent from "./makeComponent";

const MySwiper = ({ children, ...props }) => {
  return (
    <View
      style={{ flexGrow: 1, flexDirection: "column", alignItems: "stretch" }}
    >
      <Swiper
        containerStyle={{ flexGrow: 1, display: "flex", alignSelf: "stretch" }}
        innerContainerStyle={{
          flexGrow: 1,
          width: "100%",
          alignSelf: "stretch"
        }}
        loop
        controlsEnabled={false}
        timeout={10}
        maxDistanceForAction={0.05}
        {...props}
      >
        {children}
      </Swiper>
    </View>
  );
};

export default makeComponent(
  ({ Component, images }) => {
    return (
      <MySwiper>
        {images.map(image => (
          <Component key={image.id} image={image} />
        ))}
      </MySwiper>
    );
  },
  { acceptStyles: true }
);
