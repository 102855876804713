import * as React from "react";
import { getStatusBarHeight } from "react-native-status-bar-height";
import { View } from "react-native";

export default (scope, element) => {
  const { screen, header: Header } = scripts.evalProps(scope, element);

  screen.navigationOptions = ({ navigation }) => {
    return {
      header: (
        <View
          style={{
            paddingTop: getStatusBarHeight()
          }}
        >
          <Header navigation={navigation} />
        </View>
      )
    };
  };

  return screen;
};
