import { firebaseUser } from "./user-profile";
import { NavigationActions } from "react-navigation";
import delay from "xstream/extra/delay";

function navigate(navigator, routeName, params) {
  navigator.dispatch(
    NavigationActions.navigate({
      routeName,
      params
    })
  );
}

function hasRoute(nav, name) {
  if (nav.routeName === name) {
    return true;
  }

  if (Array.isArray(nav.routes) && nav.routes.length > 0) {
    return hasRoute(nav.routes[nav.index], name);
  } else {
    return false;
  }
}

export default ({ $, strict }) => {
  const navigation = $.navigation.remember();

  strict(
    firebaseUser.compose(delay(0)).compose(
      scripts.sample(navigation, (nav, user) => {
        if (user == null) {
          if (
            hasRoute(nav.state.nav, "app") ||
            hasRoute(nav.state.nav, "splash")
          ) {
            navigate(nav, "welcome");
          }
        } else {
          if (!hasRoute(nav.state.nav, "app")) {
            navigate(nav, "app");
          }
        }
      })
    )
  );

  strict(
    $.navigate.compose(
      scripts.sample(navigation, (nav, to) => navigate(nav, to))
    )
  );

  return {
    navigate: $.navigate
  };
};
