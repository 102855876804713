export default {
  helpers: {
    active: require("../assets/xd/tab-helpers-active.png"),
    inactive: require("../assets/xd/tab-helpers-inactive.png")
  },

  appointments: {
    active: require("../assets/xd/tab-upcomming-active.png"),
    inactive: require("../assets/xd/tab-upcomming-inactive.png")
  },

  settings: {
    active: require("../assets/xd/tab-profile-active.png"),
    inactive: require("../assets/xd/tab-profile-inactive.png")
  }
};
