import * as React from "react";
import Suspense from "./Suspend";
import {} from "react-native";
import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import makeComponent from "./makeComponent";

export default makeComponent(
  ({ style, item: Component, count, children }) => {
    const fallback = Array.from(new Array(count)).map((_, index) => (
      <Component key={index} />
    ));

    return (
      <Suspense style={style} fallback={fallback}>
        {children}
      </Suspense>
    );
  },
  { acceptStyles: true }
);
