import xs from "xstream";
import { makeState } from "./form-control";
import appointmentDate from "./next-appointment-date";
import dropRepeats from "xstream/extra/dropRepeats";

export const promap = (state, mapValue, mapSubject, strict) => {
  const { subject, value } = state;
  const localSubject = xs.create();
  const lifecycleState = strict(mapValue(value).remember());

  strict(
    mapSubject(localSubject).map(value => subject.shamefullySendNext(value))
  );

  return {
    value: lifecycleState,
    subject: localSubject
  };
};

const toEpoch = (epochLength, time) => {
  return time + (epochLength - (time % epochLength));
};

export default ({ $, strict }) => {
  const type = promap(
    appointmentDate,
    value$ => value$.map(value => value.type),
    subject$ =>
      subject$.compose(dropRepeats()).map(value =>
        value === "asap"
          ? { type: "asap" }
          : {
              type: "scheduled",
              scheduledTime: toEpoch(10 * 60 * 1000, Date.now())
            }
      ),
    strict
  );

  const date = promap(
    appointmentDate,
    value$ =>
      value$
        .filter(value => value.type === "scheduled")
        .map(value => new Date(value.scheduledTime)),
    subject$ =>
      subject$.map(value => ({
        type: "scheduled",
        scheduledTime: value.getTime()
      })),
    strict
  );

  return {
    type,
    date,
    options: [
      { value: "asap", label: "ASAP" },
      { value: "scheduled", label: "Scheduled" }
    ]
  };
};
