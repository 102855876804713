import applyObsevable from "./applyObservable";
import isStream from "./isStream";

const withEvent = (stream, value) => {
  if (isStream(value)) {
    return value.map(value => () => stream.shamefullySendNext(value));
  }

  return () => stream.shamefullySendNext(value);
};

withEvent[applyObsevable] = withEvent;

export default withEvent;
