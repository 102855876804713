import getHelpersByIds from "./getHelpersByIds";
import getBookingRequestById from "./getBookingRequestById";
import xs from "xstream";

export default ({ $, scope, strict }) => {
  const requestId$ = scope.props.map(({ navigation }) => {
    const id = navigation.state.params.requestId;

    return id;
  });

  const bookingRequest$ = requestId$.map(getBookingRequestById).flatten();
  const helpers$ = bookingRequest$
    .map(({ helpers }) => getHelpersByIds(helpers))
    .flatten();

  const showBeta = strict(
    xs.merge($.bookNow.mapTo(true), $.dismiss.mapTo(false)).startWith(false)
  );

  return {
    request: bookingRequest$,
    helpers: helpers$,
    showBeta
  };
};
