import { View, TouchableOpacity } from "react-native";
import makeComponent from "./makeComponent";
import * as React from "react";

export default makeComponent(
  ({ onPress, children, ...props }) => {
    return (
      <TouchableOpacity onPress={onPress}>
        <View {...props}>{children}</View>
      </TouchableOpacity>
    );
  },
  { acceptStyles: true }
);
