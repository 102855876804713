import invariant from "invariant";
import createSwitchNavigator from "react-navigation-animated-switch";

export default (scope, element) => {
  invariant(
    element.children.every(child => child.type === "Assign"),
    "Expected children of bottom navigation to be page assignments"
  );

  return createSwitchNavigator(scripts.evalScreens(scope, element), {
    initialRouteName: element.children[0].name,
    cardStyle: {
      backgroundColor: "transparent",
      opacity: 0.99
    }
  });
};
