import { Stream } from "xstream";

const objectHasStream = object => {
  for (let key in object) {
    if (object.hasOwnProperty(key) && object[key] instanceof Stream) {
      return true;
    }
  }

  return false;
};

export default objectHasStream;
