import React, { useCallback, useState, useEffect } from "react";
import { Suspend } from "./Suspend";

const identity = v => v;
const Empty = Symbol("empty");

const createControlled = (
  Component,
  propNames = ["value", "onChange"],
  mapValue = identity,
  mapEvent = identity
) => {
  return ({ control, ...props }) => {
    const [valueProp, changeProp] = propNames;

    const onChange = useCallback(
      event => {
        control.subject.shamefullySendNext(mapEvent(event));
      },
      [control]
    );

    const [value, setValue] = useState(Empty);

    useEffect(() => {
      let sub = control.value.subscribe({
        next(value) {
          setValue(mapValue(value));
        },

        complete() {
          if (sub != null) {
            sub.unsubscribe();
            sub = null;
          }
        },

        error(e) {
          console.error(e);
          setState(() => {
            throw e;
          });
        }
      });

      return () => {
        if (sub) {
          sub.unsubscribe();
          sub = null;
        }
      };
    }, [control]);

    if (value === Empty) {
      return <Suspend debug={{ control, Component }} />;
    }

    return (
      <Component
        {...props}
        {...{ [valueProp]: value, [changeProp]: onChange }}
      />
    );
  };
};

export default createControlled;
