import { Linking } from "react-native";

export default url => {
  return () =>
    Linking.openURL(
      !url.startsWith("https://") && !url.startsWith("mailto:")
        ? `https://${url}`
        : url
    );
};
