import { TouchableOpacity } from "react-native";
import makeComponent from "./makeComponent";
import * as React from "react";

export default makeComponent(
  ({ children, ...props }) => {
    return <TouchableOpacity {...props}>{children}</TouchableOpacity>;
  },
  { acceptStyles: true }
);
