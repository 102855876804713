import xs from "xstream";
import sampleCombine from "xstream/extra/sampleCombine";

const reducer = (init, events) => {
  const init$ = !scripts.isStream(init) ? xs.of(init).remember() : init;

  const result$ = init$
    .map(init =>
      xs.merge(
        xs.of(init),
        ...events.map(([event$, reduce]) =>
          event$
            .compose(sampleCombine(result$))
            .map(([event, state]) => reduce(state, event))
        )
      )
    )
    .flatten()
    .remember();

  return result$;
};

export default reducer;
