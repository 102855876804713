import firebase from "./firebase";
import xs, { MemoryStream } from "xstream";

export const getSnapshot = (
  document
): MemoryStream<firebase.firestore.DocumentSnapshot> => {
  return xs.createWithMemory({
    start(sink) {
      this.sub = (typeof document === "string"
        ? firebase.firestore().doc(document)
        : document
      ).onSnapshot(sink);
    },

    stop() {
      if (this.sub) {
        this.sub();
        this.sub = null;
      }
    }
  });
};

export const getDocument = documentRef => {
  const snapshot$ = getSnapshot(documentRef);

  return snapshot$.map(snapshot => {
    const data = snapshot.data();

    if (data != null) {
      return { ...snapshot.data(), key: snapshot.id };
    }

    return null;
  });
};

const user$: MemoryStream<firebase.User> = xs.createWithMemory({
  start(sink) {
    this.sub = firebase.auth().onAuthStateChanged(sink);
  },

  stop() {
    if (this.sub) {
      this.sub();
      this.sub = null;
    }
  }
});

export { user$ as firebaseUser };

const user = user$
  .map(value =>
    value != null ? getDocument(`users/${value.uid}`) : xs.empty()
  )
  .flatten()
  .map(value => {
    if (value == null) {
      return user$.take(1).map(v => {
        return xs
          .fromPromise(
            firebase
              .firestore()
              .collection("users")
              .doc(v.uid)
              .set({
                roles: [],
                serviceProvider: null,
                email: v.email,
                firstName: "",
                lastName: ""
              })
          )
          .filter(v => false);
      });
    }

    return xs.of(value);
  })
  .flatten()
  .remember();

user.subscribe({
  next() {},
  complete() {},
  error(e) {
    console.error(e);
  }
});

export default user;
