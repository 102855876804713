import { makeForm, makeState } from "./form-control";
import $userProfile from "./user-profile";
import xs from "xstream";
import firebase from "./firebase";

const rule = (validate, message) => value => (validate(value) ? [] : [message]);

const emptyAddress = {
  address1: "",
  address2: "",
  city: "",
  state: "Puerto Rico",
  zipCode: ""
};

export default ({ scope, strict, $ }) => {
  const props = scripts.isStream(scope.props)
    ? scope.props.remember()
    : xs.of(scope.props).remember();

  strict(
    props.map(props => {
      strict(
        props["submit-control"].submit.event.map(value =>
          $.submit.shamefullySendNext()
        )
      );
    })
  );

  const goBack$ = strict(
    props
      .map(props =>
        scripts.isStream(props["submit-control"].done)
          ? props["submit-control"].done
          : xs.of(props["submit-control"].done)
      )
      .flatten()
      .remember()
  );
  const address$ = $userProfile.map(profile =>
    profile.addresses ? profile.addresses[0] : emptyAddress
  );

  const { fields, state, submit } = makeForm($.submit, {
    address1: {
      state: makeState(
        address$.map(addr => addr.address1),
        strict
      ),
      getErrors: rule(addr => addr.length > 0, "Please enter a valid address.")
    },

    address2: {
      state: makeState(
        address$.map(addr => addr.address2),
        strict
      ),
      getErrors: () => []
    },

    city: {
      state: makeState(
        address$.map(addr => addr.city),
        strict
      ),
      getErrors: rule(city => city.length > 0, "Please enter your city")
    },

    state: {
      state: makeState(
        address$.map(addr => addr.state),
        strict
      ),
      getErrors: rule(state => state.length > 0, "Please enter your city")
    },

    zipCode: {
      state: makeState(
        address$.map(addr => addr.zipCode),
        strict
      ),
      getErrors: rule(
        zipCode => zipCode.length === 5 && zipCode.match(/^[0-9]+$/),
        "Please enter a valid 5 digit zip code"
      )
    }
  });

  const submitted$ = strict(
    submit
      .compose(
        scripts.sample(
          xs.combine(state, $userProfile),
          ([address, { key }]) => {
            return xs.fromPromise(
              firebase
                .firestore()
                .doc(`users/${key}`)
                .update({
                  addresses: [address]
                })
            );
          }
        )
      )
      .map(() => ({ type: "success" }))
      .replaceError(e => {
        console.error(e);
        return of({
          type: "error",
          message:
            "An unknown issue occured. Please try again later, or you can contact us at admin@gethallo.com"
        });
      })
  );

  const loading$ = xs
    .merge(submit.mapTo(true), submitted$.mapTo(false))
    .startWith(false);

  strict(
    submitted$
      .filter(({ type }) => type === "success")
      .compose(scripts.sample(goBack$, goBack => goBack()))
  );

  return {
    ...fields,
    loading: loading$
  };
};
