import { differenceInMinutes } from "date-fns";

export default request => {
  const minutesSinceCreated = differenceInMinutes(
    Date.now(),
    new Date(request.create.seconds * 1000)
  );

  if (request.helperPicked !== null) {
    return "resolved";
  }

  if (minutesSinceCreated > 30) {
    return "inactive";
  }

  return "active";
};
