import { makeState } from "./form-control";
import getBookingById from "./getBookingById";
import dropRepeats from "xstream/extra/dropRepeats";
import getBookingMessages from "./getBookingMessages";
import firebase from "./firebase";
import xs from "xstream";
import { firestore } from "firebase/app";

export default ({ $, scope, strict }) => {
  const bookingId$ = scope.props
    .map(({ navigation }) => navigation.state.params.bookingId)
    .compose(dropRepeats())
    .remember();

  const booking$ = bookingId$
    .map(id => getBookingById(id))
    .flatten()
    .remember();

  const provider$ = booking$
    .map(booking => booking.provider)
    .compose(dropRepeats((a, b) => a.id === b.id))
    .map(ref => scripts.getDocument(ref))
    .flatten()
    .remember();

  const messages$ = bookingId$
    .map(id => {
      return getBookingMessages(id);
    })
    .flatten()
    .compose(dropRepeats())
    .map(messages => messages.reverse());

  const composeMessage = makeState("", strict);
  const newMessages$ = $.sendMessage
    .compose(scripts.sample(composeMessage.value))
    .filter(message => message != "");

  const sentMessage$ = strict(
    newMessages$
      .compose(
        scripts.sample(bookingId$, (bid, message) => {
          return xs.fromPromise(
            firebase
              .firestore()
              .collection(`bookings/${bid}/messages`)
              .add({
                messageType: "normal",
                sentDate: firestore.FieldValue.serverTimestamp(),
                senderRole: "customer",
                params: {
                  content: message
                }
              })
          );
        })
      )
      .flatten()
  );

  strict(sentMessage$.map(v => composeMessage.subject.shamefullySendNext("")));

  const paySuccess$ = strict(
    $.confirmAndPay.compose(
      scripts.sample(bookingId$, id => {
        firebase
          .functions()
          .httpsCallable("appFunctions-customerConfirmCapture")({
          bookingId: id
        });
      })
    )
  );

  const base$ = booking$.map(booking =>
    Object.values(booking.services)
      .map(service => service.quantity * service.serviceValue.price.value)
      .reduce((a, b) => a + b)
  );
  const platformFee$ = base$.map(total => total * PLATFORM_FEE);
  const salesTax$ = base$.map(total => total * SALES_TAX);

  return {
    composeMessage,
    confirmLoading: xs
      .merge($.confirmAndPay.mapTo(true), paySuccess$.mapTo(false))
      .startWith(false),
    messages: messages$,
    booking: booking$.debug("booking"),
    provider: provider$,
    platformFee: platformFee$,
    salesTax: salesTax$,
    total: xs
      .combine(base$, platformFee$, salesTax$)
      .map(([a, b, c]) => a + b + c)
  };
};
