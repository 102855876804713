import * as React from "react";
import Suspense from "./Suspend";
import { View } from "react-native";
import makeComponent from "./makeComponent";

export const SuspenseContext = React.createContext();

export default makeComponent(
  ({ children, fallback, style }) => {
    return (
      <Suspense style={style} fallback={fallback || <View />}>
        {children}
      </Suspense>
    );
  },
  { acceptStyles: true }
);
