import firebase from "./firebase";
import xs from "xstream";

const requestFirebase = firebase
  .functions()
  .httpsCallable("appFunctions-getMatchingProviders");

const getMatchingHelpers = (skillsRequested, serviceZipCode) => {
  return xs
    .fromPromise(requestFirebase({ skillsRequested, serviceZipCode }))
    .map(res => res.data);
};

export default getMatchingHelpers;
