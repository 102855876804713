import getMatchingHelpers from "./getMatchingHelpers";
import userProfile$ from "./user-profile";
import xs from "xstream";
import createBookingRequest from "./createBookingRequest";

export default ({ scope, strict, $ }) => {
  const navigation$ = scope.props.map(props => props.navigation);
  const requestInfo$ = xs
    .combine(navigation$, userProfile$)
    .map(([navigation, userProfile]) => {
      const skills = navigation.state.params.skills;
      const category = navigation.state.params.category;
      const [address] = userProfile.addresses;
      return { skills, categoryId: category, address, userId: userProfile.key };
    });

  const matchingHelpers$ = xs
    .combine(navigation$, userProfile$)
    .map(([navigation, userProfile]) => {
      const skills = navigation.state.params.skills;
      const [address] = userProfile.addresses || [];

      return getMatchingHelpers(skills, address);
    })
    .flatten();

  const category$ = scope.props
    .map(({ screenProps, ...props }) => {
      const { category } = props.navigation.state.params;
      return category;
    })
    .map(cat => resolveCategory(cat))
    .flatten();

  strict(
    $.goBack.compose(
      scripts.sample(navigation$, navigation => navigation.pop())
    )
  );

  strict(
    $.requestHelpers
      .compose(
        scripts.sample(
          xs.combine(requestInfo$, matchingHelpers$),
          ([{ userId, skills, address, categoryId }, helpers]) => {
            return createBookingRequest({
              userId,
              skills,
              categoryId: categoryId,
              helpers: helpers.map(helper => helper.key),
              address
            });
          }
        )
      )
      .flatten()
      .compose(
        scripts.sample(navigation$, (nav, ref) => {
          nav.navigate("booking-request", { requestId: ref.id });
        })
      )
  );

  return {
    helpers: matchingHelpers$,
    modalVisible: $.closeModal.mapTo(false).startWith(true)
  };
};
