import * as React from "react";
import combineObject from "./combineObject";
import evalPropsAndChildren from "./evalPropsAndChildren";
import ObserveProps from "./ObservableProps";
import { useRef, useImperativeHandle } from "react";

const makeComponent = (Component, { acceptStyles }) => {
  const value = (scope, element) => {
    const { props, children } = evalPropsAndChildren(
      acceptStyles,
      false,
      element,
      scope
    );

    if (scripts.objectHasStream(props)) {
      return (
        <ObserveProps
          value={combineObject(props).remember()}
          Component={Component}
          children={children}
        />
      );
    }

    return React.createElement(Component, props, children);
  };

  value.acceptStyles = acceptStyles;

  return value;
};

export default makeComponent;
