import * as React from "react";
import { View } from "react-native";

export const Suspend = React.lazy(() => new Promise(() => {}));

export default ({ fallback, style, children }) => {
  return (
    <View style={[style, { display: "flex" }]}>
      <React.Suspense fallback={fallback}>{children}</React.Suspense>
    </View>
  );
};
