import firebase from "./firebase";
import xs from "xstream";

const requestFirebase = firebase
  .functions()
  .httpsCallable("appFunctions-getHelpersByIds");

const getHelpersByIds = ids => {
  return xs.fromPromise(requestFirebase({ ids })).map(res => res.data);
};

export default getHelpersByIds;
