import firebase from "./firebase";
import xs from "xstream";

const requestFirebase = firebase
  .functions()
  .httpsCallable("appFunctions-createBookingRequest");

const createBookingRequest = ({
  userId,
  skills,
  categoryId,
  helpers,
  address
}) => {
  return xs.fromPromise(
    requestFirebase({
      address,
      skills,
      helpers,
      categoryId,
      userId,
      accepted: [],
      rejected: []
    }).then(result => ({ id: result.data }))
  );
};

export default createBookingRequest;
