import firebase from "./firebase";
import xs from "xstream";

export default bookingId => {
  return xs
    .create({
      start(sink) {
        this.sub = firebase
          .firestore()
          .collection(`bookings/${bookingId}/messages`)
          .orderBy("sentDate", "desc")
          .onSnapshot(sink);
      },

      stop() {
        if (this.sub) {
          this.sub();
        }
      }
    })
    .map(data => {
      return data.docs.map(doc => ({ ...doc.data(), key: doc.id }));
    })
    .remember();
};
