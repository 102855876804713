import {
  format,
  differenceInCalendarYears,
  differenceInCalendarDays
} from "date-fns";

export default dateRaw => {
  const date = dateRaw.seconds ? new Date(dateRaw.seconds * 1000) : date;
  const now = Date.now();
  const daysDiff = differenceInCalendarDays(now, date);

  if (daysDiff === 0) {
    return format(date, "p");
  }

  if (daysDiff === 1) {
    return "Yesterday";
  }

  if (daysDiff < 7) {
    return format(date, "EEE");
  }

  if (differenceInCalendarYears(now, date) === 0) {
    return format(date, "MMM d");
  }

  return format(date, "P");
};
