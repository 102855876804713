import invariant from "invariant";
import * as React from "react";
import ObserveProps from "./ObservableProps";
import { mkMap } from "./mk-component";

const invalidForMessage =
  'expected for to use "for id :of (expression) :by (id | expression)';

export default (scope, element) => {
  const [identifier, _of, collectionExp, _by, key] = element.props;

  invariant(identifier.type === "Identifier", invalidForMessage);
  invariant(scripts.assertKeyword(_of, "of"), invalidForMessage);
  invariant(scripts.assertKeyword(_by, "by"), invalidForMessage);

  const collection = scope.eval(collectionExp);
  const getKey =
    key.type === "Identifier" ? value => value[key.name] : scope.eval(key);

  invariant(
    getKey instanceof Function,
    'expected "for" key to be an identifier or a function'
  );

  const EvalChild = mkMap({
    scope,
    children: element.children,
    key: identifier.name,
    prop: "value"
  });

  const MapChildren = ({ values }) => {
    return (
      <>
        {values.map(child => (
          <EvalChild key={getKey(child)} value={child} />
        ))}
      </>
    );
  };

  if (!scripts.isStream(collection)) {
    return React.createElement(
      React.Fragment,
      {},
      collection.map(child => {
        const key = getKey(child);
        const children = scope
          .branch(new Map([[identifier.name, child]]))
          .evalChildren(element.children);

        return React.createElement(React.Fragment, { key }, children);
      })
    );
  } else {
    return (
      <ObserveProps
        value={collection.map(collection => ({ values: collection }))}
        Component={MapChildren}
      />
    );
  }
};
