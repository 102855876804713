import defer from "./defer";
import strapi from "./strapi";
import xs from "xstream";
import { AsyncStorage } from "react-native";
import cachedRequests from "../.cached-assets/cached-requests.json";

const getFromCache = id => {
  return xs.of(cachedRequests["categories"]).remember();
};

const saveCache = value => {
  AsyncStorage.setItem(`categories`, JSON.stringify(value));
};

export default defer(() =>
  getFromCache()
    .map(value => {
      if (value === null) {
        return xs
          .fromPromise(strapi.getEntries("categories", { public_eq: true }))
          .map(categories => {
            saveCache(categories);
            return categories;
          });
      } else {
        return xs.of(value);
      }
    })
    .flatten()
).remember();
