import xs from "xstream";
import sampleCombine from "xstream/extra/sampleCombine";

export default ({ strict, scope }) => {
  const goBack = xs.create();
  const navigation$ = scope.props.map(props => props.navigation);

  strict(
    goBack.compose(sampleCombine(navigation$)).map(([, navigation]) => {
      navigation.pop();
    })
  );

  return {
    back() {
      goBack.shamefullySendNext();
    }
  };
};
