import { Set } from "immutable";
import xs from "xstream";
import resolveSkills from "./resolve-skills";
import userProfile$ from "./user-profile";

export default ({ $, scope, strict }) => {
  const navigation = scope.props.map(props => props.navigation);

  const categoryId$ = scope.props.map(({ screenProps, ...props }) => {
    const { category } = props.navigation.state.params;
    return category;
  });

  const selectedSkills$ = strict(
    scripts.reducer(Set(), [
      [
        $.toggleSkill,
        (set, skill) => (set.has(skill) ? set.remove(skill) : set.add(skill))
      ]
    ])
  );

  const isAddressEmpty$ = userProfile$
    .map(value => {
      return value.addresses == null || value.addresses[0] == null;
    })
    .remember();

  strict(
    $.findHelpers.compose(
      scripts.sample(
        xs.combine(navigation, selectedSkills$, categoryId$, isAddressEmpty$),
        ([navigation, skills, categoryId, isAddressEmpty]) => {
          if (isAddressEmpty) {
            navigation.push("location-control", {
              continue: {
                action: "Next",
                route: "list-helpers",
                params: {
                  skills: skills.toArray(),
                  category: categoryId
                }
              }
            });
          } else {
            navigation.push("list-helpers", {
              skills: skills.toArray(),
              category: categoryId
            });
          }
        }
      )
    )
  );

  return {
    skills: categoryId$.map(cat => resolveSkills(cat)).flatten(),
    selected: selectedSkills$.remember()
  };
};
