import { AsyncStorage } from "react-native";
import xs from "xstream";
import { getSnapshot } from "./user-profile";
import consume from "./consume";
import firebaseApp from "./firebase";
import sample from "./sample";
import user from "./user-profile";

const messaging = firebaseApp.messaging();

export const askForPermission = async user => {
  try {
    await messaging.requestPermission();
    const token = await messaging.getToken();

    await firebaseApp
      .firestore()
      .doc(`users/${user.key}/notificationChannels/${token}`)
      .set({
        type: "push",
        token
      });

    return token;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const key = "hallo:askedForPush";

const askedForPermissionUser$ = xs
  .fromPromise(AsyncStorage.getItem(key))
  .debug("asked");
const permissionToken$ = xs.fromPromise(messaging.getToken()).debug("token");
export const hasPermission$ = permissionToken$;
xs.combine(user, permissionToken$)
  .map((user, value) => {
    if (value == null) {
      return xs.of(false);
    }

    return getSnapshot(`users/${user.key}/notficationChannels/${value}`).map(
      snap => snap.exists
    );
  })
  .flatten()
  .remember()
  .debug("hasPermission");

const triggerAsk$ = hasPermission$.filter(value => {
  return value === false;
});

consume(
  triggerAsk$
    .compose(
      sample(
        xs.combine(user, askedForPermissionUser$),
        ([user, userPermission]) => {
          return user.key !== userPermission ? user : null;
        }
      )
    )
    .filter(value => value !== null)
    .map(value => {
      //return askForPermission(value);
    })
);
