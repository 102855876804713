import transform from "css-to-react-native";
import { vw, vh } from "react-native-expo-viewport-units";
import colors from "./colors";
import { getStatusBarHeight } from "react-native-status-bar-height";

const shadows = {
  2: "0 0 10px rgba(141, 151, 158, 0.3)",
  3: "0 0 20px rgba(141, 151, 158, 0.2)"
};

const borders = {
  divider: {
    width: "1px",
    style: "solid #E5E5E5"
  },

  input: {
    width: "2px",
    style: "solid #E5E5E5"
  },

  profile: {
    width: "4px",
    style: "solid #ffffff"
  },

  selected: {
    width: "2px",
    style: `solid ${colors["button-primary"]}`
  }
};

const parseUnit = value => {
  if (value === "auto") {
    return value;
  }

  if (value.endsWith("vw")) {
    return vw(Number(value.slice(0, -2))) + "px";
  }

  if (value.endsWith("vh")) {
    return vh(Number(value.slice(0, -2))) + "px";
  }

  return value.endsWith("p")
    ? value.slice(0, -1) + "%"
    : parseInt(value, 10) * 4 + "px";
};

const memoChoice = (char, ...choices) => {
  return choices.find(side => side.startsWith(char));
};

const specialKeywords = [
  [
    /^m([tblrxy]?)-(-?[0-9]+(?:p|vw|vh)?|auto)$/,
    (kw, [, type, valueStr]) => {
      const value = parseUnit(valueStr);

      if (type === "") {
        return [["margin", value]];
      }

      if (type === "x") {
        return [["margin-left", value], ["margin-right", value]];
      }

      if (type === "y") {
        return [["margin-top", value], ["margin-bottom", value]];
      }

      const side = ["top", "bottom", "left", "right"].find(side =>
        side.startsWith(type)
      );

      return [[`margin-${side}`, value]];
    }
  ],

  [
    /^p([tblrxy]?)-(-?[0-9]+(?:p|vw|vh)?|auto)$/,
    (kw, [, type, valueStr]) => {
      const value = parseUnit(valueStr);

      if (type === "") {
        return [["padding", value]];
      }

      if (type === "x") {
        return [["padding-left", value], ["padding-right", value]];
      }

      if (type === "y") {
        return [["padding-top", value], ["padding-bottom", value]];
      }

      const side = ["top", "bottom", "left", "right"].find(side =>
        side.startsWith(type)
      );

      return [[`padding-${side}`, value]];
    }
  ],

  [/^mt--status-bar$/, () => [["margin-top", -getStatusBarHeight() + "px"]]],
  [/^pt-status-bar$/, () => [["padding-top", getStatusBarHeight() + "px"]]],
  [/^mt-status-bar$/, () => [["margin-top", getStatusBarHeight() + "px"]]],

  [
    /^h-(([0-9]+(?:p|vw|vh)?)|auto)$/,
    (kw, [, type, valueStr]) => {
      const value = parseUnit(valueStr);

      return [[`height`, value]];
    }
  ],

  [
    /^w-(([0-9]+(?:p|vw|vh)?)|auto)$/,
    (kw, [, type, valueStr]) => {
      const value = parseUnit(valueStr);

      return [[`width`, value]];
    }
  ],

  [
    /^shadow-([0-9])$/,
    (kw, [, shadow]) => {
      return [["box-shadow", shadows[shadow]]];
    }
  ],

  [
    /^z-(-?[0-9]+)$/,
    (kw, [, value]) => {
      return [["z-index", value]];
    }
  ],

  [
    /^bg-([a-z0-9_-]+)$/,
    (kw, [, color]) => {
      return [["background-color", colors[color]]];
    }
  ],

  [
    /^self-(center|stretch|start|end)$/,
    (kw, [, rule]) => {
      const withFlex = ["start", "end"].includes(rule) ? `flex-${rule}` : rule;
      return [["align-self", withFlex]];
    }
  ],
  [
    /^items-(center|stretch|start|end)$/,
    (kw, [, rule]) => {
      const withFlex = ["start", "end"].includes(rule) ? `flex-${rule}` : rule;
      return [["align-items", withFlex]];
    }
  ],

  [
    /^cover$/,
    () => [["align-self", "stretch"], ["flex", "1"], ["flex-basis", "100%"]]
  ],

  [/^h$/, () => [["flex-direction", "row"]]],

  [
    /^radius-([0-9]+p?)$/,
    (kw, [, value]) => [[`border-radius`, parseUnit(value)]]
  ],

  [
    /^radius-(b|t)(r|l)-([0-9]+p?)$/,
    (kw, [, y, x, value]) => [
      [
        `border-${memoChoice(y, "top", "bottom")}-${memoChoice(
          x,
          "left",
          "right"
        )}-radius`,
        parseUnit(value)
      ]
    ]
  ],

  [/^v$/, () => [["flex-direction", "column"]]],

  [/^capitalize$/, () => [["text-transform", "capitalize"]]],
  [/^uppercase$/, () => [["text-transform", "uppercase"]]],
  [/^wrap$/, () => [["flex-wrap", "wrap"]]],
  [/^grow$/, () => [["flex-grow", "1"]]],
  [/^flex$/, () => [["flex", "1"]]],
  [/^flex-2$/, () => [["flex", "2"]]],
  [/^shrink$/, () => [["flex-shrink", "1"]]],
  [/^absolute$/, () => [["position", "absolute"]]],
  [/^fixed$/, () => [["position", "fixed"]]],
  [/^sticky$/, () => [["position", "sticky"]]],
  [/^relative$/, () => [["position", "relative"]]],

  [
    /^(bottom|top|left|right)-([0-9]+p?)$/,
    (kw, [, side, value]) => [[side, parseUnit(value)]]
  ],

  [
    /^(overflow)-(hidden|visible|scroll)$/,
    (kw, [, dimension, type]) => [[dimension, type]]
  ],

  [
    /^basis-(([0-9]+(?:p|vw|vh)?)|auto)$/,
    (kw, [, valueStr]) => [["flex-basis", parseUnit(valueStr)]]
  ],

  [/^line-([0-9]+)$/, (kw, [, valueStr]) => [["line-height", valueStr]]],

  [
    /^text-(left|center|right)$/,
    (kw, [, valueStr]) => [["text-align", valueStr]]
  ],

  [/^text-([0-9]+)$/, (kw, [, value]) => [["font-size", value + "px"]]],

  [
    /^b(b|l|r|t)?-([a-z0-9\-]+)$/,
    (kw, [, sideChar, value]) => {
      const borderStyle = borders[value];
      const side = memoChoice(sideChar, "left", "right", "bottom", "top");

      return sideChar == null
        ? [["border", `${borderStyle.width} ${borderStyle.style}`]]
        : [[`border-${side}`, `${borderStyle.width} ${borderStyle.style}`]];
    }
  ],

  [
    new RegExp(`^text-(${Object.keys(colors).join("|")})`),
    (kw, [, color]) => [["color", colors[color]]]
  ]
];

const getKeywordStyle = kw => {
  for (let [pattern, create] of specialKeywords) {
    const match = kw.name.match(pattern);

    if (match != null) {
      return create(kw, match);
    }
  }

  console.error("missing style", kw.name);
  return [];
};

const parseKeywordStyles = props => {
  return transform(
    props.filter(kw => kw.type === "Identifier").flatMap(getKeywordStyle)
  );
};

export default parseKeywordStyles;
