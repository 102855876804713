import strapi from "./strapi";
import xs from "xstream";
import { AsyncStorage } from "react-native";
import cachedRequests from "../.cached-assets/cached-requests.json";

const getFromCache = id => {
  return xs
    .of(cachedRequests["categories"].find(v => v._id === id).skills)
    .remember();
};

const saveCache = (id, value) => {
  AsyncStorage.setItem(`skills/${id}`, JSON.stringify(value));
};

const resolveSkills = id => {
  return getFromCache(id)
    .map(value => {
      if (value === null) {
        return xs
          .fromPromise(strapi.getEntries("skills", { "category._id": id }))
          .map(skills => {
            saveCache(id, skills);
            return skills;
          });
      }

      return xs.of(value);
    })
    .flatten()
    .remember();
};

export default resolveSkills;
