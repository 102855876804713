import * as firebaseApp from "firebase";
import xs from "xstream";

export default helperId => {
  return xs
    .fromPromise(
      firebaseApp
        .firestore()
        .collection(`serviceProviders/${helperId}/services`)
        .get()
    )
    .map(value => value.docs.map(value => ({ ...value.data(), key: value.id })))
    .remember();
};
