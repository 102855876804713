import * as React from "react";
import { Suspend } from "./Suspend";

const Empty = Symbol("Empty");

class ObserveProps extends React.PureComponent {
  state = {
    props: Empty
  };
  sub = null;

  constructor(props) {
    super(props);
    this.elRef = React.createRef();

    this.sub = props.value.subscribe({
      next: this.onChange,

      error: e => {
        console.error(e);
        this.setState(() => {
          throw e;
        });
      },

      complete() {
        if (this.sub) {
          this.sub.unsubscribe();
          this.sub = null;
        }
      }
    });
  }

  componentWillMount() {
    this.mounted = true;
  }

  onChange = props => {
    if (this.mounted) {
      this.setState({ props });
    } else if (!this.unmounted) {
      this.state = { props };
    }
  };

  setNativeProps = props => {
    if (this.elRef.current && this.elRef.current.setNativeProps) {
      elRef.setNativeProps(props);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
    this.unmounted = true;

    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
  }

  render() {
    const { props } = this.state;
    const { Component, children } = this.props;

    if (props === Empty) {
      return <Suspend debug={this} />;
    }

    return React.createElement(Component, {
      ref: this.elRef,
      children,
      ...props
    });
  }
}

ObserveProps.displayName = "ObserveProps";

export default ObserveProps;
