import { letPropsAndChildren } from "./evalPropsAndChildren";

const makeNodeFunction = fn => {
  const value = (scope, element) => {
    const { props, children } = letPropsAndChildren(
      false,
      false,
      element,
      scope
    );
    return fn(props, children);
  };

  return value;
};

export default makeNodeFunction;
