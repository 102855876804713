import { SafeAreaView, View } from "react-native";
import makeComponent from "./makeComponent";
import * as React from "react";

const WithView = ({ styles, children, ...props }) => {
  return (
    <SafeAreaView
      style={{ flex: 1, alignSelf: "stretch", flexDirection: "column" }}
      {...props}
    >
      <View
        style={[
          {
            flex: 1,
            alignSelf: "stretch",
            flexDirection: "column"
          },
          props.styles
        ]}
      >
        {children}
      </View>
    </SafeAreaView>
  );
};

export default makeComponent(WithView, { acceptStyles: true });
