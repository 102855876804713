import invariant from "invariant";
import xs, { Stream } from "xstream";
import dropRepeats from "xstream/extra/dropRepeats";
import applyObsevable from "./scripts/applyObservable";
import combineObject from "./scripts/combineObject";

const isObsevable = (value): value is Stream<any> => value instanceof Stream;
const toObsevable = value =>
  isObsevable(value) ? value : xs.of(value).remember();

const lift = (...values) => {
  if (!values.some(value => isObsevable(value))) {
    const [fn, ...args] = values;
    return fn(...args);
  }

  const obs = values.map(toObsevable);

  return xs
    .combine(...obs)
    .map(([fn, ...args]: [Function, ...any[]]) => toObsevable(fn(...args)))
    .flatten()
    .remember();
};

const specialForms = {
  if: (scope, expression) => {
    const [, condition, consequent, alternative] = expression.elements;
    const conditionValue = evaluateExpression(scope, condition);
    let consequentValue;
    const getConsequent = () =>
      consequentValue ||
      (consequentValue = evaluateExpression(scope, consequent));
    let alternativeValue;
    const getAlternative = () =>
      alternativeValue ||
      (alternativeValue = evaluateExpression(scope, alternative));

    if (!isObsevable(conditionValue)) {
      return conditionValue ? getConsequent() : getAlternative();
    }

    const getConsequentObs = () =>
      consequentValue ||
      (consequentValue = toObsevable(evaluateExpression(scope, consequent)));
    const getAlternativeObs = () =>
      alternativeValue ||
      (alternativeValue = toObsevable(evaluateExpression(scope, alternative)));

    return conditionValue
      .compose(dropRepeats())
      .map(condition => (condition ? getConsequentObs() : getAlternativeObs()))
      .flatten()
      .remember();
  }
};

export const evaluateExpression = (scope, expression) => {
  if (expression.type === "Identifier") {
    return scope.get(expression.name, expression);
  }

  if (expression.type === "MemberExpression") {
    return lift(value => {
      const val = value[expression.member.name];

      if (val && val instanceof Function) {
        return val.bind(value);
      }

      return val;
    }, evaluateExpression(scope, expression.target));
  }

  if (expression.type === "StringLiteral" || expression.type === "Number") {
    return expression.value;
  }

  if (expression.type === "Keyword") {
    return expression;
  }

  if (expression.type === "DictExpression") {
    return combineObject(
      Object.fromEntries(
        scripts
          .parseProps(expression.elements)
          .map(([key, value]) => [key, evaluateExpression(scope, value)])
      )
    ).remember();
  }

  if (expression.type === "ConsExpression") {
    const [head = null, ...tail] = expression.elements;
    if (
      head &&
      head.type === "Identifier" &&
      specialForms.hasOwnProperty(head.name)
    ) {
      return specialForms[head.name](scope, expression);
    }

    const headValue = evaluateExpression(scope, head);

    if (headValue && headValue[applyObsevable]) {
      return headValue[applyObsevable](
        ...tail.map(value => evaluateExpression(scope, value))
      );
    }

    const elements = tail.map(exp => evaluateExpression(scope, exp));

    return lift(headValue, ...elements);
  }

  invariant(false, "can't evaluate expression %s", expression.type);
};
