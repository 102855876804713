import { Map, Record, updateIn } from "immutable";
import xs from "xstream";
import dropRepeats from "xstream/extra/dropRepeats";
import bookHelper from "./bookHelper";
import getHelperServices from "./get-helper-services";
import getBookingById from "./getBookingById";
import delay from "xstream/extra/delay";
import getEnv from "../environment";

const Item = Record({
  quantity: 0
});

export default ({ $, scope, strict }) => {
  const paymentElement$ = $.paymentElement.remember();

  const navigation$ = scope.props
    .map(value => {
      return value.navigation;
    })
    .remember();
  const bookingId$ = scope.props
    .map(props => {
      return props["booking-id"];
    })
    .compose(dropRepeats())
    .remember();
  const helperId$ = scope.props
    .map(props => {
      return props["helper-id"];
    })
    .compose(dropRepeats())
    .remember();

  const savedLineItems$ = bookingId$
    .map(id => {
      return id != null && id
        ? getBookingById(id).map(booking =>
            Map(booking.services).map(v => new Item(v))
          )
        : xs.of(Map());
    })
    .flatten()
    .remember();

  const lineItems$ = savedLineItems$
    .map(initial =>
      xs
        .merge(
          $.addService.compose(
            scripts.sample(lineItems$, (items, key) => {
              return items.update(key, (item = new Item()) =>
                item.update("quantity", value => value + 1)
              );
            })
          ),

          $.removeService.compose(
            scripts.sample(lineItems$, (items, key) => {
              if (items.getIn([key, "quantity"]) === 1) {
                return items.remove(key);
              }

              return updateIn(items, [key, "quantity"], value => value - 1);
            })
          )
        )
        .startWith(initial)
    )
    .flatten()
    .remember();

  const services$ = helperId$
    .map(id => getHelperServices(id))
    .flatten()
    .remember();

  const submission$ = $.bookHelper
    .compose(
      scripts.sample(
        xs.combine(lineItems$, paymentElement$),
        ([lineItems, paymentElement]) => {
          if (lineItems.size <= 0) {
            return xs.of({
              type: "error",
              message: "Please add a service to book."
            });
          }

          return xs.fromPromise(
            (getEnv().stripePK.startsWith("pk_test")
              ? Promise.resolve({ paymentMethod: { id: "pm_card_visa" } })
              : scripts.stripe.createPaymentMethod("card", paymentElement)
            ).then(result => {
              if (result.error) {
                return {
                  type: "error",
                  message: result.error.message
                };
              }

              return {
                type: "ready",
                lineItems,
                paymentMethod: result.paymentMethod.id
              };
            })
          );
        }
      )
    )
    .flatten();

  const status$ = submission$.startWith({ type: "ok" });

  const success$ = strict(
    submission$
      .filter(v => v.type === "ready")
      .compose(
        scripts.sample(
          xs.combine(helperId$, services$, bookingId$),
          ([helperId, services, bookingId], { lineItems, paymentMethod }) => {
            return bookHelper(
              bookingId,
              helperId,
              paymentMethod,
              lineItems.toJS(),
              services
            ).compose(
              scripts.sample(
                xs.combine(navigation$, bookingId$),
                ([navigation, bookingId], params) => {
                  if (bookingId == null) {
                    navigation.navigate("appointment-detail", params);
                  }
                }
              )
            );
          }
        )
      )
      .flatten()
  );

  const loading$ = xs
    .merge(
      $.bookHelper.mapTo(true).debug("hay"),
      submission$
        .compose(delay(0))
        .filter(v => v.type !== "ready")
        .mapTo(false)
        .debug("false"),
      success$.mapTo(false).debug("wat")
    )
    .startWith(false);

  return {
    loading: loading$,
    lineItems: lineItems$,
    status: status$,
    showError: xs
      .merge(
        status$.filter(value => value.type === "error").mapTo(true),
        $.dismissError.mapTo(false)
      )
      .startWith(false),
    services: services$,
    visible: $.dismiss.mapTo(false).startWith(true),
    done: $.done,
    dismiss: $.dismiss
  };
};
