const PLATFORM_FEE = 0.05;
const SALES_TAX = 0.115 * PLATFORM_FEE;

export default services => {
  const base = Object.values(services)
    .map(service => service.quantity * service.serviceValue.price.value)
    .reduce((a, b) => a + b);
  const platformFee = base * PLATFORM_FEE;
  const salesTax = base * SALES_TAX;

  return {
    base,
    platformFee,
    salesTax,
    total: base + platformFee + salesTax
  };
};
