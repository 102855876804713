import * as React from "react";
import makeComponent from "./makeComponent";
import stripe from "./stripe";

export default makeComponent(
  ({ onPaymentElement }) => {
    const ref = React.useRef();
    const [cardEl, setCardEl] = React.useState();

    React.useEffect(() => {
      if (cardEl) {
        var paymentRequest = stripe.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: "Demo total",
            amount: 1000
          },
          requestPayerName: true,
          requestPayerEmail: true
        });
        var elements = stripe.elements();
        var prButton = elements.create("paymentRequestButton", {
          paymentRequest: paymentRequest
        });

        paymentRequest
          .canMakePayment()
          .then(() => {
            // prButton.mount(ref.current);
          })
          .catch(e => {
            console.log(e);
          });

        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        var style = {
          base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        };

        // Create an instance of the card Element.
        var card = elements.create("card", { style: style });

        onPaymentElement(card);

        // Add an instance of the card Element into the `card-element` <div>.
        card.mount(cardEl);
      }
    }, [cardEl]);

    return (
      <div>
        <div ref={ref}></div>
        <div ref={setCardEl}></div>
      </div>
    );
  },
  { acceptStyles: true }
);
