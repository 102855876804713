export default stream => {
  stream.subscribe({
    next() {},
    error(e) {
      console.error(e);
    },
    end() {}
  });

  return stream;
};
