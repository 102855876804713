import firebaseApp from "./firebase";
import { firestore } from "firebase/app";
import userProfile from "./user-profile";
import startDate from "./next-appointment-date";
import { mapObjIndexed, toPairs } from "ramda";
import xs from "xstream";

export default (bookingId, helperId, paymentMethod, lineItems, services) => {
  const client$ = userProfile.map(profile =>
    firebaseApp.firestore().doc(`users/${profile.key}`)
  );
  const location$ = userProfile.map(profile => ({
    type: "client",
    options: profile.addresses[0]
  }));
  const helper = firebaseApp.firestore().doc(`serviceProviders/${helperId}`);
  const appointmentStart$ = startDate.value.map(time =>
    time.type === "asap" ? "ASAP" : new Date(time.scheduledTime)
  );

  const servicesMap = mapObjIndexed(
    (value, key) => ({
      ...value,
      serviceValue: services.find(service => service.key === key),
      service: firebaseApp
        .firestore()
        .doc(`serviceProviders/${helperId}/services/${key}`)
    }),
    lineItems
  );

  const pricing = scripts.getPricing(servicesMap);

  return scripts
    .combineObject({
      client: client$,
      provider: helper,
      status: "awaiting-confirmation",
      services: servicesMap,
      location: location$,
      appointmentStart: appointmentStart$,
      createDate: firestore.FieldValue.serverTimestamp()
    })
    .take(1)
    .map(booking => {
      if (bookingId != null) {
        delete booking["createDate"];
        const ref = firebaseApp.firestore().doc(`bookings/${bookingId}`);
        return xs.fromPromise(ref.update(booking)).mapTo(ref);
      } else {
        return xs.fromPromise(
          firebaseApp
            .firestore()
            .collection("bookings")
            .add(booking)
        );
      }
    })
    .flatten()
    .map(value => {
      const confirmPaymentRequest = {
        paymentMethod,
        bookingId: value.id,
        totalQuotedPrice: pricing.total
      };

      return xs
        .fromPromise(
          firebaseApp
            .functions()
            .httpsCallable("appFunctions-confirmAppointmentPayment")(
              confirmPaymentRequest
            )
            .catch(e => {
              console.error(e);
            })
        )
        .map(() => ({ bookingId: value.id }));
    })
    .flatten();
};
