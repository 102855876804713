const parseProps = props => {
  if (props.length === 0) {
    return [];
  }

  const [head, ...rest] = props;

  if (head.type === "Keyword") {
    if (rest.length === 0) {
      return [];
    }

    const [value, ...nextParse] = rest;

    return [[head.id.name, value], ...parseProps(nextParse)];
  }

  return parseProps(rest);
};

export default parseProps;
