import xs from "xstream";
import combineObject from "./combineObject";
import isStream from "./isStream";

export const makeForm = (triggerSubmit, fields) => {
  const errorsProxy$ = xs.create();
  const showFieldErrors$ = errorsProxy$.startWith(false).remember();

  const formState$ = combineObject(
    Object.fromEntries(
      Object.entries(fields).map(([key, value]) => {
        return [key, value.state.value];
      })
    )
  );

  const fieldsWithErrors = Object.entries(fields).map(([key, value]) => {
    return [key, withErrors(showFieldErrors$, value.state, value.getErrors)];
  });

  const formHasErrors = hasErrors(fieldsWithErrors.map(field => field[1]));
  const showErrors$ = triggerSubmit.compose(scripts.sample(formHasErrors));

  errorsProxy$.imitate(showErrors$);

  return {
    submit: triggerSubmit
      .compose(scripts.sample(formHasErrors))
      .filter(hasErrors => hasErrors === false),
    state: formState$,
    fields: Object.fromEntries(fieldsWithErrors)
  };
};

export const makeState = (initial, strict) => {
  const subject = xs.create();

  const lifecycleState = isStream(initial)
    ? strict(
        initial
          .map(value => subject.startWith(value))
          .flatten()
          .remember()
      )
    : strict(subject.startWith(initial));

  return {
    value: lifecycleState,
    subject: subject
  };
};

export const withErrors = (showErrors, control, getErrors) => {
  const fieldErrors = control.value.map(getErrors);
  return {
    ...control,
    errors: fieldErrors,
    showErrors: showErrors
  };
};

export const hasErrors = fields => {
  return xs
    .combine(...fields.map(field => field.errors))
    .map(value => value.some(child => child.length > 0))
    .remember();
};
