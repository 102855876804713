import invariant from "invariant";
import { createStackNavigator } from "react-navigation-stack";
import { fromRight } from "react-navigation-transitions";

export default (scope, element) => {
  invariant(
    element.children.every(child => child.type === "Assign"),
    "Expected children of bottom navigation to be page assignments"
  );
  const props = scripts.evalProps(scope, element);

  return createStackNavigator(scripts.evalScreens(scope, element), {
    mode: props.mode || "card",
    headerMode: props.header || props.mode === "modal" ? "none" : "screen",
    defaultNavigationOptions: ({ navigation }) => ({
      headerStyle: {
        display: "none"
      },
      cardStyle: {
        backgroundColor: "transparent",
        opacity: 0.99
      }
    }),
    initialRouteName: element.children[0].name,

    transitionConfig: props.transition
      ? () => props.transition()
      : () => fromRight(),

    transparentCard: props.mode === "modal",

    cardStyle: {
      backgroundColor: "transparent",
      opacity: 0.99
    }
  });
};
