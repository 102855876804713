import dropRepeats from "xstream/extra/dropRepeats";
import getHelperById from "./get-helper-by-id";
import getHelperServices from "./get-helper-services";

export default ({ $, scope, strict }) => {
  const helperId$ = scope.props
    .map(props => {
      return props.navigation.state.params.helper;
    })
    .compose(dropRepeats())
    .remember();

  const helper$ = helperId$
    .map(value => getHelperById(value))
    .flatten()
    .remember();

  const services$ = helperId$
    .debug("helperId")
    .map(id => getHelperServices(id))
    .flatten()
    .remember();

  return {
    helper: helper$,
    services: services$
  };
};
